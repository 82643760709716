/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createSlide () {
        return new Swiper('#slider--slide', {
            slidesPerView: 1,
            spaceBetween: 15,
            // loop: true,
            navigation: {
                nextEl: '.slider--slide-next',
                prevEl: '.slider--slide-prev',
            },
            pagination: {
                el: '.swiper-pagination--slide',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1240: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                1440: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                }
            }
        })
    }

    createNews () {
        return new Swiper('#slider--news', {
            slidesPerView: 2,
            spaceBetween: 70,
            // loop: true,
            navigation: {
                nextEl: '.slider--news-next',
                prevEl: '.slider--news-prev',
            },
            pagination: {
                el: '.swiper-pagination--news',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 70,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--slide').length) > 0) {
            this.createSlide()
        }

        if (parseInt($('#slider--news').length) > 0) {
            this.createNews()
        }
    }
}
