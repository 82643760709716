/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonOrder {
    classButton = 'button--order'
    classButtonActive = 'button--order-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)
        })
    }
}
