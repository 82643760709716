/*global $*/
/*eslint no-undef: "error"*/
export default class TabButton {
    classButton = 'tab--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = 'tab-block-' + $(this).data('block')

            if ($(`#${block}`).length > 0) {
                $('html,body').stop().animate(
                    {
                        scrollTop: $(`#${block}`).offset().top - 40
                    },
                    500
                )
            }
        })
    }
}
