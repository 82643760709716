/*global $*/
/*eslint no-undef: "error"*/
export default class Map {
    classButton = 'map--button'
    idIFrame = 'map--iframe'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click( function () {
            let mapIframe = document.getElementById(self.idIFrame)

            mapIframe.setAttribute('src', mapIframe.getAttribute('data-src'))
            mapIframe.removeAttribute('data-src')
        })
    }
}
